import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKButton from "../../components/MKButton"
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { withStyles } from "@material-ui/core/styles";
import ReCAPTCHA from "react-google-recaptcha";
import validateMerchantID from "../../providers/validateMerchantID";
import emailValidation from "../../helpers/emailValidation"
import forgotPasswordreq from "../../providers/forgotPasswordreq"
import { BsCheck2 } from 'react-icons/bs';
import logo from "../../assets/images/logo.svg";


const recaptchaRef = React.createRef();
const siteKey = window.spconfig?.config.reCaptKey;


const ColorButton = styled(MKButton)(() => ({
    backgroundColor: "#C2C2C2",
    '&:hover': {
        backgroundColor: "#3CAC33",
    },
}))

const MyTextField = withStyles(() => ({
    root: {
        "&.MuiInputLabel": {
            fontFamily: "OpenSansRegular"
        },

        "& .MuiInput-root": {
            fontSize: "14px",
            fontFamily: "OpenSansMedium"
        },
        "& .MuiInputBase-input": {
            padding: "16.5px 14px"
        },


    }
}))(TextField)


const ForgotPassword = ({ setStepperCount}) => {
    const siteKey = window.spconfig?.config.reCaptKey;

    useEffect(() => {
        setStepperCount(-1);
    }, [])

    const onChangeCaptcha = (value) => {
        setCaptchaCode(value)
    }

    const [authToken, setAuthToken] = useState("");
    const [captchaCode, setCaptchaCode] = useState("");
    const [step, setStep] = useState(1);
    const [merchant_id, setMerchantID] = useState("");
    const [submitMID, setSubmitMID] = useState(false);
    const [midError, setmidError] = useState(false);
    const [username, setUsername] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userEmailError, setUserEmailError] = useState(false);
    const [commonError, setCommonError] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);

    const submitMerchantID = async() => {
        if (captchaCode === "" || captchaCode === null || merchant_id === ""){
            return;
        }
        setSubmitMID(true);
        const data = {
            merchant_id : merchant_id.trim()
        }
        const validation = await validateMerchantID(data)
        if (validation.data.ResponseStatus === 'Success'){
            setAuthToken(validation.data.ResponseContent.data.authmtoken)
            setStep(2);
        }else {
            setmidError(true); 
        }
        
    }

    const onEmailBlur = (event) => {
        const value = event.target.value
        if (value === "") {
            return;
        }
        const result = emailValidation(value)
        if (!result) {
            setUserEmailError(true);
        }
    }

    const onFormSubmit = async () => {
        setFormSubmit(true);
        if (username === "" || userEmail === "" || userEmailError){
            return;
        }
        const data = {
            username : username,
            email: userEmail
        }

        const result = await forgotPasswordreq(data, authToken);
        if (result.data.ResponseStatus === 'Success'){
            setStep(3);
        }else {
            setCommonError(true);
        }
    }


   

    return(
        <MKBox mb='250px'>

            <Grid item xs={12} lg={12} style={{ marginTop: "30px" }}>
                <MKBox sx={{ justifyContent: "center", textAlign: "center", align: "center" }}>
                    <img src={logo} alt="LOGO"></img>
                </MKBox>
            </Grid>  

            < Container component="main" maxWidth="xs" justify="center"  style={{ overflowX: "hidden" }}>                    
                {step === 1 ? 
                <MKBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="50vh"
                    mt="80px"
                >

                    <Grid container justifyContent="center" maxWidth="xs" style={{ overflowX: "hidden" }}>

                        <Grid item xs={12} lg={12} sx={{ mb: "20px", }}>
                            <MKTypography sx={{
                                fontSize: "22px",
                                textAlign: "left",
                                fontFamily: "OpenSansRegular",
                                fontWeight: "600",
                                color: "#2E4455",
                                mb: "10px"
                            }}>
                                Forgot Password
                            </MKTypography>
                            <MKTypography sx={{
                                fontSize: "16px",
                                textAlign: "left",
                                fontFamily: "OpenSansRegular",
                                color: "#2E4455",
                                mb: "30px"
                            }}>
                                Help us recover your credentials securely.
                                Provide your Merchant ID generated at the time of
                                portal registration.
                            </MKTypography>
                        </Grid>


                        <Grid xs={12} lg={12} sx={{ mb: "20px" }}>
                            <MyTextField id="outlined-size-normal"
                                label="Merchant ID"
                                placeholder="Merchant ID issued during registration"
                                fullWidth
                                value={merchant_id}
                                error={(submitMID && merchant_id === "") || midError}
                                helperText={submitMID && merchant_id === "" ? "MerchantID is required" : midError ?"Sorry! It is not a valid Merchant ID.":""}
                                onChange={(e) => {
                                    setSubmitMID(false);
                                    setmidError(false);
                                    setMerchantID(e.target.value)
                                }
                                }
                                onBlur={()=> {
                                    setSubmitMID(true);
                                }}
                                variant="standard" />
                        </Grid>

                        <Grid xs={12} lg={12} sx={{ mb: "40px" }}>
                                <form form onSubmit={() => { recaptchaRef.current.execute(); }}>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={siteKey}
                                onChange={onChangeCaptcha}
                            />
                            </form>
                        </Grid>



                        <Grid item xs={12} lg={12} >
                            <ColorButton
                                style={{ color: "#FFFFFF", fontFamily: "OpenSansSemiBold" }}
                                variant="contained"
                                onClick={submitMerchantID}
                                sx={{
                                    width: "100%",
                                    height: "48px",
                                    backgroundColor: "#50B748",
                                    color: "white",
                                    textTransform: "capitalize",
                                    fontFamily: "OpenSansSemiBold",
                                    fontSize: "15px",
                                }}
                            >
                                Next
                            </ColorButton>
                        </Grid>
                        <Grid item xs={12} lg={12} mt={2}>
                            <MKTypography sx={{
                                fontSize: "14px",
                                textAlign: "center",
                                fontFamily: "OpenSansRegular",
                                color: "#202223",
                            }}>
                                New to ShelfPerks?
                                <a href="/" style={{
                                    color: "#50B748",
                                    fontWeight: "700"
                                }}> Register Now</a>
                            </MKTypography>
                        </Grid>

                    </Grid>
                </MKBox>:""}
                
                {step === 2 ? <MKBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="50vh"
                    mt="80px"
                    
                >

                    <Grid container justifyContent="center" maxWidth="xs" style={{ overflowX: "hidden" }}>

                        <Grid item xs={12} lg={12} sx={{ mb: "20px", }}>
                            <MKTypography sx={{
                                fontSize: "22px",
                                textAlign: "left",
                                fontFamily: "OpenSansRegular",
                                fontWeight: "600",
                                color: "#2E4455",
                                mb:"10px"
                            }}>
                                Forgot Password
                            </MKTypography>
                            
                            <MKTypography sx={{
                                fontSize: "16px",
                                textAlign: "left",
                                fontFamily: "OpenSansRegular",
                                color: "#2E4455",
                                mb: "30px"
                            }}>
                                Thanks! You are just one step away from resetting your password.
                            </MKTypography>
                        </Grid>


                        <Grid xs={12} lg={12} sx={{ mb: "20px" }}>
                            <MyTextField id="outlined-size-normal"
                                label="Username"
                                placeholder="Enter your username"
                                fullWidth
                                error={(formSubmit && username === "")}
                                helperText={(formSubmit && username === "") ? "Username is required" : ""}
                                value={username}
                                onChange={(e) => {
                                    const un = e.target.value.replaceAll(" ", "");
                                    setUsername(un)
                                    setFormSubmit(false)
                                    setCommonError(false)
                                }}
                                variant="standard" />
                        </Grid>
                        <Grid xs={12} lg={12} sx={{ mb: "30px" }}>
                            <MyTextField id="outlined-size-normal"
                                label="Email"
                                placeholder="Email associated with your username"
                                fullWidth
                                error={(formSubmit && userEmail === "") || userEmailError}
                                helperText={userEmailError?"Invalid Email":(formSubmit && userEmail === "") ? "Email is required" : ""}
                                value={userEmail}
                                onChange={(e) => {
                                    const email = e.target.value.replaceAll(" ", "");
                                    setUserEmail(email)
                                    setFormSubmit(false)
                                    setUserEmailError(false)
                                    setCommonError(false)
                                }}
                                onBlur={onEmailBlur}
                                variant="standard" />
                        </Grid>

                        



                        <Grid item xs={12} lg={12} >
                            <ColorButton
                                style={{ color: "#FFFFFF", fontFamily: "OpenSansSemiBold" }}
                                variant="contained"
                                onClick={onFormSubmit}
                                sx={{
                                    width: "100%",
                                    height: "48px",
                                    backgroundColor: "#50B748",
                                    color: "white",
                                    textTransform: "capitalize",
                                    fontFamily: "OpenSansSemiBold",
                                    fontSize: "15px",
                                }}
                            >
                                Submit
                            </ColorButton>
                        </Grid>

                        {commonError ?
                            <MKTypography sx={{
                                fontSize: "16px",
                                fontFamily: "OpenSansRegular",
                                color: "#FF4040",
                                padding: "10px"
                            }} >
                                Sorry! The username and email address entered do not match.
                            </MKTypography> : ""}

                        <Grid item xs={12} lg={12} mt={2}>
                            <MKTypography sx={{
                                fontSize: "14px",
                                textAlign: "center",
                                fontFamily: "OpenSansRegular",
                                color: "#6D7175",
                            }}>
                                New to ShelfPerks?
                                <a href="/" style={{
                                    color: "#50B748",
                                    fontWeight: "700"
                                }}> Register Now</a>
                            </MKTypography>
                        </Grid>

                    </Grid>
                </MKBox>:""}

                {step === 3 ? 
                    <Grid sx={{ minHeight: "60vh" }} >
                        
                        <Grid item xs={12} sm={12} md={12} justifyContent="center" display="flex">
                            <MKBox component="main"
                                sx={{
                                    mt: {
                                        lg: "180px",
                                        xs: "180px",
                                    },
                                    width: "86px",
                                    height: "85px",
                                    borderRadius: '43px',
                                    backgroundColor: "#50B748"
                                }}>
                                <div className='check-icon' style={{ align: "center" }}>
                                    <BsCheck2 />
                                </div>
                            </MKBox>
                        </Grid>
                        <Grid item xs={12} lg={12} justifyContent="center">
                            <MKTypography
                                fontSize="24px"
                                sx={{
                                    mt: "82px",
                                    fontFamily: "OpenSansSemiBold",
                                    textAlign: "center",
                                    color: "#2E4455"
                                }}>
                                Done! We have sent the details to your registered email.
                            </MKTypography>
                        </Grid>
                    </Grid>
                : ""}
                    

                

                

            </Container >
        </MKBox >
    )
}

export default ForgotPassword;