import * as React from 'react';
import Grid from "@mui/material/Grid";
import "../App.css"
import playstore from "../assets/images/googleplay.png";
import appstore from "../assets/images/appstore.png";
import MKBox from "../components/MKBox";
import MKTypography from "../components/MKTypography";
import footerData from './data';
import newsLetter from "./newsletter";
import SocialIcons from "./socialIcons";
import shelfperks from "../assets/images/logo.svg";

function Download() {
    return (
        <>
            <MKBox component="li" p={0} pb={"16px"} m={0} lineHeight={1.25}>
                <MKTypography
                    variant="button"
                    textTransform="capitalize"
                    fontFamily="OpenSansRegular"
                    key="download"
                    sx={{ fontWeight: { xs: '600', sm: '500' }, fontSize: { xs: '24px', sm: '20px', lg: '32px' } }}
                >
                    Download our App
                </MKTypography>
            </MKBox>
            <MKBox component="li" p={0} m={0} lineHeight={1.25} className="download-apps">
                <a target="_blank" rel="noreferrer" href="https://apple.co/3v9EUou" aria-label="appstore">
                    <MKBox component="img" src={appstore} alt="" className='appstore' style={{ height: "44px", width: '162px' }} key="appstore" />
                </a>
                <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.shelfperks.android.sp" aria-label="playstore">
                    <MKBox component="img" src={playstore} className='playstore' alt="" style={{ height: "44px", width: '162px' }} key="playstore" />
                </a>
            </MKBox>
        </>
    )
}

function BookADemo() {
    return (
        <a className='bookDemoBtn' rel="noreferrer" target='_blank' style={{ textDecoration: 'none',}} href='https://shelfperks.com/book-a-demo' >
                Book a Demo
            </a>
    )
}

function feedback() {
    return (
        <>
            <MKBox sx={{ fontSize: { xs: '14px', sm: '16px', lg: '20px' }, pt: { xs: '24px', sm: '36px' }, pb: { xs: '24px', sm: '36px' } }}>
                feedback@shelfperks.com
            </MKBox>
            <MKBox className='semi-bold' sx={{ fontSize: { xs: '14px', sm: '14px', lg: '20px' } }} >

               <a className='terms' target="_blank" rel="noreferrer" href="https://shelfperks.com/terms-of-service"> Terms of Service </a> | <a className='terms' target="_blank" rel="noreferrer" href="https://shelfperks.com/privacy-policy">Privacy Policy</a>
            </MKBox>
        </>
    )
}

function copyright() {
    return (
        <>
            <MKBox sx={{ fontSize: { xs: '14px', lg: "20px", }, mt:{xs:'24px', sm:0} }} >
                <MKBox sx={{ display: { xs: 'none', sm: 'block' } }} style={{ marginBottom: "12px" }}> <img className='footer-logo' src={shelfperks} alt="LOGO"></img>
                </MKBox>
                <span className='copy-text'>
                Copyright ©{new Date().getFullYear()} ShelfPerks.com all rights reserved.
                </span>
            </MKBox>
        </>
    )
}


export default function NewFooter() {

    const [emailError, setEmailError] = React.useState(false);
    return (
        <>
            <MKBox component="footer" fontFamily="OpenSansRegular" sx={{ pt: { xs: '24px', md: "24px", lg: '50px' }, pb: { xs: '16px', md: "24px", lg: '36px' } }}>
                <Grid container style={{ backgroundColor: "#F0EEEE" }}
                    align="start" fontFamily="OpenSansRegular" justifyContent="space-between"
                    sx={{
                        pl: { lg: '0px', md: "0px", sm: "0px" },
                    }}>

                    <Grid item xs={12} xl={5.8} lg={5.8} md={5.8} sm={5.8}>
                        <MKBox>
                            {newsLetter(setEmailError)}
                        </MKBox>
                        <MKBox sx={{ pb: { xs: '24px', sm: '36px' }, pt: { xs: '0px', sm: emailError ? '0px' : '12px' } }}>
                            {SocialIcons()}
                        </MKBox>
                        <MKBox sx={{ pb: { xs: '24px', sm: '36px' } }}>
                            {BookADemo()}
                        </MKBox>
                        <Grid item xs={12} sx={{ display: { xs: "none", sm: 'block' } }} align="start">
                            <MKBox pb={1} sx={{ listStyle: "none", ml: { xs: 2, sm: 0 }, pt: { xs: 2, lg: 2, md: 2, sm: 1 } }}>
                                <Download />
                            </MKBox>
                            {feedback()}
                        </Grid>


                    </Grid>




                    <Grid item xs={12} xl={5.8} lg={5.5} md={5.5} sm={5.5} sx={{pl:{xl:'20px', lg:'20px', md:'0px'} ,display:'flex', flexDirection:'column', justifyContent:'space-between' }}>
                        <MKBox sx={{ display: "flex", flexWrap: 'wrap' }}>
                            {footerData?.menu?.map((items, index) => (
                                <Grid key={items.title} item xs={6} sx={{ mt: { xs: '24px', sm: '0px' }, mb: { xs: '0px', sm: '36px' } }}>
                                    <MKBox m={0}
                                        sx={{
                                            listStyle: "none",
                                            pt: { lg: 0.5, xs: 0, },
                                            display: "flex",
                                            flexDirection: { xs: "column" },
                                            pb: { sm: 1, xs: 0, md: 1, lg: 1 },
                                            fontSize: { xs: '14px', sm: '14px', md: '16px', lg: '20px' },
                                            fontWeight: '700'
                                        }}
                                        width="fit-content"
                                        justifyContent="start"
                                    >
                                        <div className={`bold${index === 1 || index === 3 ? " extraspace" : ""}`}>
                                            {items?.title}
                                        </div>
                                        {items?.data?.map(({ name, href }) => (
                                            <MKBox key={name} component="li" m={0} sx={{ lineHeight: { xs: 2.2, lg: 1.25 }, pt: { xs: "8px", sm: "26px", md: '26px', lg: '22px', xl:'24px' }, fontSize: { xs: '14px', sm: '14px', md: '16px' } }}>
                                                <MKTypography
                                                    component="a"
                                                    href={href}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    variant="button"
                                                    fontWeight="regular"
                                                    textTransform="capitalize"
                                                    fontFamily="OpenSansRegular"
                                                    sx={{ fontSize: { xs: '14px', sm: '14px', md: '16px', lg: '20px' }, }}
                                                >
                                                    {name === "Enterprise Solutions" ?
                                                        <div className={`semi-bold${index === 1 || index === 3 ? " extraspace" : ""}`}>{name}</div>
                                                        :
                                                        <div className={`${index === 1 || index === 3 ? " extraspace" : ""}${name === 'Careers' || name === 'Become a Partner'? " topMargin" : ""}`}>{name}</div>
                                                    }
                                                </MKTypography>
                                            </MKBox>
                                        ))}
                                    </MKBox>
                                </Grid>
                            ))}
                        </MKBox>


                        <Grid item xs={12} sx={{ display: { xs: "block", sm: 'none' } }} align="start">
                            <MKBox sx={{ listStyle: "none", pt: { xs: 2, lg: 2, md: 2, sm: 2 } }}>
                                <Download />
                            </MKBox>
                            {feedback()}
                        </Grid>


                        {copyright()}





                    </Grid>
                </Grid>


            </MKBox>
        </>

    )
}