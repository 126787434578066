/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React Base Styles
import colors from "../../../assets/theme/base/colors";

const { info, dark } = colors;

export default {
  html: {
    scrollBehavior: "smooth",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  ".MuiTypography-root":{
    fontFamily:"OpenSansRegular"
  },
  ".MuiBox-root.price-card:hover, .MuiButton-outlined": {
    background: "white"
  },
  ".price-card:hover .MuiTypography-root": {
    color: "white"
  },
  ".price-card:hover .MuiTypography-root.title": {
    color: "#FFFFFF !important"
  },
  ".price-card:hover .MuiDivider-root": {
    background: "#389B30"
  },

  ".price-card.MuiTypography-root, .footer-content .MuiTypography-root, .MuiTypography-root.content-block": {
    color: "#202223"
  },

  ".price-card.MuiTypography-root.title": { color: "#7F7F7F", fontFamily:"OpenSansRegular" },
  ".semi-bold": {
    fontWeight: "600",
    fontFamily:"OpenSansMedium"
  },
  ".bold": {
    fontWeight: "700",
    fontFamily: "OpenSansMedium"
  },

  ".MuiTypography-root.opensans-bold": {
    fontWeight: "700"
  },
  ".opensans-bold": {
    fontWeight: "700"
  },

  ".MuiTypography-root.pricingcard-duration, .MuiTypography-root.pricingcard-description, .MuiTypography-root.pricingcard-specification": {
    fontSize: "14px !important",
    fontFamily:"OpenSansRegular !important",

    "@media (min-width:1400px)": {
      fontSize: "16px !important",
    }
  },

};
