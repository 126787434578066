import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKButton from "../../components/MKButton"
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import CustomPhoneNumber from "../../components/SPPhoneBlock/index";
import { withStyles } from "@material-ui/core/styles";
import emailValidation from "../../helpers/emailValidation";
import ReCAPTCHA from "react-google-recaptcha";
import forgotMerchantIDService from "../../providers/forgotMerchantidService";
import { BsCheck2 } from 'react-icons/bs';
import logo from "../../assets/images/logo.svg";

const ColorButton = styled(MKButton)(() => ({
    backgroundColor: "#C2C2C2",
    '&:hover': {
        backgroundColor: "#3CAC33",
    },
}))

const MyTextField = withStyles(() => ({
    root: {
        "&.MuiInputLabel": {
            fontFamily: "OpenSansRegular"
        },

        "& .MuiInput-root": {
            fontSize: "14px",
            fontFamily: "OpenSansMedium"
        },
        "& .MuiInputBase-input": {
            padding: "16.5px 14px"
        },


    }
}))(TextField)

const ForgotMerchantID = ({ setStepperCount }) => {

    const siteKey = window.spconfig?.config.reCaptKey;
    useEffect(() => {
        setStepperCount(-1);
    }, [])

    const [userEmail, setUserEmail] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [formSubmit, setFormSubmit] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [captchaCode, setCaptchaCode] = useState("");
    const [processStatus, setProcessStatus] = useState(false);
    const [commonError, setCommonError] = useState(false);


    const onChangeCaptcha = (value) => {
        setCaptchaCode(value)
    }


    const onEmailBlur = (event) => {
        const value = event.target.value
        if (value === "") {
            return;
        }
        const result = emailValidation(value)
        if (!result) {
            setEmailError(true);
        }
    }

    const onPhoneBlur = (event) => {
        if (String(event.target.value) === "" || event.target.value === undefined) {
            return;
        }
        const result = isValidPhoneNumber(String(userPhone))
        if (!result) {
            setPhoneError(true);
        }
    }

    const onSubmit = async () => {
        setFormSubmit(true);
        if (userPhone === "" || userEmail === "" || captchaCode === "" || captchaCode===null) {
            return;
        } else {
            const data = {
                email: userEmail,
                phone: userPhone,
                captchaCode: captchaCode
            }

            const result = await forgotMerchantIDService(data);
            if (result.data.ResponseStatus === 'Success') {
                setProcessStatus(true);
            } else {
                setCommonError(true);
            }
        }
    }

    // this.commonServ.forgotMerchant(formData).subscribe(data => {
    //     if (data.ResponseStatus === 'Success') {
    //         this.merchantBoolean = false;
    //         this.finalBoolean = true;
    //     } else if (data.ResponseStatus === 'Error') {
    //         this.commonError = true;
    //     }
    // });


    return (
        <MKBox mb='250px'>
            < Container component="main" maxWidth="xs" justify="center" >
                <Grid item xs={12} lg={12} style={{ marginTop: "30px" }}>
                    <MKBox sx={{ justifyContent: "center", textAlign: "center", align: "center" }}>
                        <img src={logo} alt="LOGO"></img>
                    </MKBox>
                </Grid>

                {!processStatus ?
                <>
                        

                    <MKBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="50vh"
                        mt="80px"
                    >
                        
                        
                        <Grid container justifyContent="center" maxWidth="xs">
                            <Grid item xs={12} lg={12}>
                                <MKTypography sx={{
                                    fontSize: "22px",
                                    textAlign: "left",
                                    fontFamily: "OpenSansRegular",
                                    fontWeight: "600",
                                    color: "#2E4455",
                                    mb: "10px"
                                }}>
                                    Forgot Merchant ID
                                </MKTypography>
                               
                                <MKTypography sx={{
                                    fontSize: "16px",
                                    textAlign: "left",
                                    fontFamily: "OpenSansRegular",
                                    color: "#2E4455",
                                    mb: "20px"
                                }}>
                                    Enter your email address and mobile number
                                    used at the time of Merchant Portal
                                    registration.
                                </MKTypography>
                            </Grid>


                            <Grid xs={12} lg={12} sx={{  }}>
                                <Grid >
                                    <MyTextField
                                        margin="normal"
                                        value={userEmail}
                                        onChange={(event) => {
                                            setFormSubmit(false)
                                            setEmailError(false)
                                            setCommonError(false)
                                            const email = event.target.value.replaceAll(" ", "");
                                            setUserEmail(email)
                                        }}
                                        error={(emailError) || (formSubmit && userEmail === "")}
                                        helperText={emailError ? "Invalid Email" : formSubmit && userEmail === "" ? "Email is required" : ""}
                                        onBlur={onEmailBlur}
                                        id="outlined-size-normal"
                                        label="Email"
                                        name='userEmail'
                                        placeholder="Registered merchant email"
                                        fullWidth
                                        InputLabelProps={{
                                            style: { fontFamily: "OpenSansRegular" }
                                        }}
                                        variant="standard" />

                                </Grid >
                                <Grid xs={12} lg={12} sx={{ mb: "50px" }}>
                                    <PhoneInput
                                        style={{ borderBottom: "2px solid #BABFC5", }}
                                        defaultCountry={"US"}
                                        inputComponent={CustomPhoneNumber}
                                        name="userPhone"
                                        error={(phoneError) || (formSubmit && userPhone === "")}
                                        helperText={phoneError ? "Invalid Phone Number" : formSubmit && userPhone === "" ? "Phone Number is required" : ""}
                                        value={userPhone}
                                        onChange={(event) => {
                                            setFormSubmit(false)
                                            setPhoneError(false)
                                            setCommonError(false)
                                            setUserPhone(String(event))
                                        }}
                                        countryCallingCodeEditable={true}
                                        onBlur={onPhoneBlur}
                                    />
                                </Grid>
                                <Grid xs={12} lg={12} sx={{ mb: "20px" }}>
                                    <ReCAPTCHA
                                        sitekey={siteKey}
                                        onChange={onChangeCaptcha}
                                    />
                                </Grid>

                            </Grid>



                            <Grid item xs={12} lg={12} >
                                <ColorButton
                                    style={{ color: "#FFFFFF", fontFamily: "OpenSansSemiBold" }}
                                    variant="contained"
                                    onClick={onSubmit}
                                    sx={{
                                        width: "100%",
                                        height: "48px",
                                        backgroundColor: "#50B748",
                                        color: "white",
                                        textTransform: "capitalize",
                                        fontFamily: "OpenSansSemiBold",
                                        fontSize: "15px",
                                    }}
                                >
                                    Submit
                                </ColorButton>
                                {commonError ?
                                    <MKTypography sx={{
                                        fontSize: "16px",
                                        fontFamily: "OpenSansRegular",
                                        color: "#FF4040",
                                        padding:"10px"
                                    }} >
                                        Sorry! The email address and phone number entered do not match.
                                    </MKTypography> : ""}
                            </Grid>
                            <Grid item xs={12} lg={12} mt={2}>
                                <MKTypography sx={{
                                    fontSize: "14px",
                                    textAlign: "center",
                                    fontFamily: "OpenSansRegular",
                                    color: "#6D7175",
                                }}>
                                    New to ShelfPerks?

                                    <a href="/" style={{
                                        color: "#50B748",
                                        fontWeight: "700"
                                    }}> Register Now</a>
                                </MKTypography>
                            </Grid>

                        </Grid>
                    </MKBox> </> :

                    <Grid sx={{ minHeight: "60vh" }} >

                        <Grid item xs={12} sm={12} md={12} justifyContent="center" display="flex">
                            
                            

                            <MKBox component="main"
                                sx={{
                                    mt: {
                                        lg: "180px",
                                        xs: "180px",
                                    },
                                    width: "86px",
                                    height: "85px",
                                    borderRadius: '43px',
                                    backgroundColor: "#50B748"
                                }}>
                                <div className='check-icon' style={{ align: "center" }}>
                                    <BsCheck2 />
                                </div>
                            </MKBox>
                        </Grid>
                        <Grid item xs={12} lg={12} justifyContent="center">
                            <MKTypography
                                fontSize="24px"
                                sx={{
                                    mt: "82px",
                                    fontFamily: "OpenSansSemiBold",
                                    textAlign: "center",
                                    color:"#2E4455"
                                }}>
                                Done! We have sent the details to your registered email.
                            </MKTypography>
                        </Grid>
                    </Grid>

                }



            </Container >
        </MKBox >
    )
}

export default ForgotMerchantID;