const axios = require('axios');

async function subscribeNewsLetter(value) {
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiZ3Jvd19jbG91ZGZ1bmN0aW9uc19kZXYiLCJpYXQiOjE2NTE2MzM3MTV9.OVae4_a2AreFZ_8Wf504clbHgX6GD3tjFyjOjimUSm8";
    const baseURL = 'https://723ibe72fif35b27d3fdb23.shelfperks.com';
    const url = baseURL + "/newsletter/insert_new_entry";
    const inputObj = {
        "inputObject": {
            "email": value['email'],
            "name": "",
            "ipv4 Address": value['ipv4_Address'],
            "ipv6 Address": value['ipv6_Address'],
            "location": value['location'],
            "userAgent": value['userAgent'],
            "date_time": new Date().toUTCString(),
            "source": "ShelfPerks",
        }
    }
    const headerObj = {
        "content-type": "application/json; charset=UTF-8",
        'x-access-token': `${token}`
    };

    const response = await axios({
        method: "post",
        url: url,
        data: inputObj,
        headers: headerObj
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            });
    return response;
}

export default subscribeNewsLetter;