import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import MKTypography from "../../../../components/MKTypography";
import MKBox from "../../../../components/MKBox";
import MKButton from "../../../../components/MKButton";
import AddOnModal from './addOnModal';
import AddOnLocationModal from './addOnLocationModal';

import { getAddonsList } from "../../../../providers/planService";
import currencyConverter from "../../../../helpers/currencyConverter";
import AddIcon from '@mui/icons-material/Add';
import { getCurrentAdons } from '../../../../providers/planService';

const AddonsList = ({ planName, addonListChange }) => {

    const [addonList, setAddonList] = useState([]);

    const addlocPre = [
        'You are opening another location but want to keep your current subscription plan. You can easily add additional locations.',
        'One additional location comes with 1 additional terminal and 1 additional user. '
    ]   

    const addUserPre = [
        'Onboarding more employees? ',
        'Great! Give them access to your ShelfPerks Store Operating System.',
        'Add additional users as an add-on.'
    ]

    const addTermPre = [
        'You are getting more customers but don’t have enough checkout terminal.',
        'You can add additional terminals and turn your mobile devices into instant POS checkouts.',
        'Terminals are tied to a specific location.'
    ]

    // const addStoreWebsite = [
    //     'Increase your revenue with online sales. You can take your store online instantly. All the order fulfillment features will automatically be turned on.Inventory updates for online and in-store sales are also tied up.Make you customers happy with convenient online ordering, different pickup and delivery options!',
    // ]

    const addStoreWebsite = [
        'Increase your revenue with online sales.',
        'You can take your store online instantly.',
        'Integrated with in-store/online inventory updates and order fulfillment.',
        'Make your customers happy with convenient pickups and delivery options.'

    ]

    // useEffect( () => {

    //     async function getAddons() {
    //        const result =  await getAddonsList();
    //         if (result && result.length){
    //             if (planName){

    //                 if (planName === 'plus' || planName === 'premium'){
    //                     const filterAddon = result.filter((addon) => {
    //                         return addon.code !== "STORE_WEBSITE"
    //                     })
    //                     console.log('filterAddon useefect one', filterAddon)
    //                     setAddonList(filterAddon);    
    //                 }
    //             else{

    //                     const filteredAddon = await getAddonListAfterFilter(result)
    //                     setAddonList(filteredAddon);
    //             }
    //         }
    //         }
            
    //     }

    //     getAddons()
    // }, [])

    useEffect(() => {
        async function getAddons() {
            const result = await getAddonsList();
            if (result && result.length) {
                if (planName) {

                    if (planName === 'plus' || planName === 'premium') {
                        const filterAddon = result.filter((addon) => {
                            return addon.code !== "STORE_WEBSITE"
                        })
                        setAddonList(filterAddon);
                    }
                else {
                        const filteredAddon = await getAddonListAfterFilter(result)
                        setAddonList(filteredAddon);
                }
            }
        }
        }
        getAddons()
    }, [planName, addonListChange])


    const getAddonListAfterFilter = async (result) => {
        let list = result;
        const addonsList = await getCurrentAdons()
        if (addonsList.data.recurring) {
            addonsList.data.recurring.forEach((addon) => {
                if (addon.plancode === 'sp_site30'){
                    list = result.filter((addon) => {
                        return addon.code !== "STORE_WEBSITE"
                    })
                }
            })
        }
        return list;
    }

    const [planDetails, setPlanDetails] = useState({});

    const toggleModal = async (addOnName, oneTimeObj, recurringObj) => {

       
        setPlanDetails({
            name: addOnName,
            onetime: oneTimeObj,
            recurring: recurringObj
        })
        if (addOnName.toLowerCase().includes('location')) {
            setLocModal(!locModal);
        }else {
            setModal(!modal);
        }
        


    }

    // General
    const [modal, setModal] = useState(false);

    if (modal) {
        document.body.classList.add('active-addOnModal')
    } else {
        document.body.classList.remove('active-addOnModal')
    }

    // Location Popup
    const [locModal, setLocModal] = useState(false);

    if (locModal) {
        document.body.classList.add('active-addOnLocModal')
    } else {
        document.body.classList.remove('active-addOnLocModal')
    }

    


    return (
        <>
            <MKTypography mt={"55px"} fontSize="18px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, marginBottom: '15px', fontFamily: 'OpenSansRegular' }}>Add more</MKTypography>

            <Grid container item>
                

                {addonList.map(({ name, onetime, recurring }) =>
                    <Grid item container xs={12} sm={8} lg={4} md={6} xl={4}>
                        <MKBox key={name}
                            sx={{
                                width: { md: "363px", sm: "363px", xs: "363px" },
                                height: "auto",
                                borderRadius: "8px",
                                border: "#DCDCDC 1px solid",
                                mr: "30px",
                                mb: "30px",

                                '&:hover': {
                                    border: "#50B748 1px solid",
                                }
                            }}>
                            <Grid item container xs={12} sx={{
                                mt: 2,
                                pl: "24px",
                                listStyle: "none",
                                display: "flex",
                                flexDirection: { xs: "row" },
                            }} justifyContent="space-between">
                                <div>
                                    <MKTypography mt="14x" fontSize="20px" style={{ color: "#202223", letterSpacing: "0.18px", textAlign: "left", opacity: 1, }}> <span className="semi-bold">{name}</span></MKTypography>
                                </div>
                                <Grid item>
                                    <div>
                                        <MKButton
                                            variant="outlined"
                                            color="primary"
                                            className="addOn_Add"
                                            size="medium"
                                            sx={{

                                                fontSize: "14px",
                                                borderRadius: "4px",
                                                color: "#50B748",
                                                borderColor: "#50B748",
                                                mr: { xs: "20px", sm: "20px", md: "20px" },
                                                textTransform: "capitalize",
                                                fontFamily: "OpenSansMedium",
                                                // verticalAlign: "middle",
                                                
                                            }}
                                            onClick={() => {
                                                toggleModal(name, onetime, recurring)
                                            }
                                            }
                                        >
                                            <AddIcon style={{
                                                fontSize: '18px', marginRight: '5px', fontWeight: '600'}} 
                                                sx={{ "&:hover": { color: "#FFFFFF" } }}
                                            />
                                            Add
                                        </MKButton>
                                    </div>
                                </Grid>


                            </Grid>
                            <Grid container pl="24px" pb="16px" pr='16px' >
                                {onetime?.price? 
                                    <MKTypography fontSize="12px" sx={{ mt: 0.5 , mr: '120px'}} style={{ color: "#202223", letterSpacing: "0.12px", textAlign: "left", opacity: 1, lineHeight: "2", fontFamily: 'OpenSansRegular' }}><span style={{ fontFamily: "OpenSansMedium", color: '#50B748', fontSize: '18px', letterSpacing: "0px", fontWeight: 600 }}> {currencyConverter(onetime?.price)} </span> One Time </MKTypography>
                                    : ""}
                                {recurring?.price ? 
                                    <MKTypography fontSize="12px" sx={{ mt: 0.5 }} style={{ color: "#202223", letterSpacing: "0.12px", textAlign: "left", opacity: 1, lineHeight: "2", fontFamily: 'OpenSansRegular' }}><span style={{ fontFamily: "OpenSansMedium", color: '#50B748', fontSize: '18px', letterSpacing: "0px", fontWeight: 600 }}> {currencyConverter(recurring?.price)} </span> Recurring Monthly </MKTypography>

                                    // <MKTypography fontSize="14px" sx={{ mt: 0.5 }} style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, lineHeight: "2", fontFamily: 'OpenSansMedium' }}>Price : <span style={{ fontFamily: "OpenSansMedium" }}> {currencyConverter(recurring.price)} (Recurring Monthly) </span> </MKTypography>
                                    : ""}
                            </Grid>

                            <MKTypography pl="24px" pb="16px" pr='16px' fontSize="14px" sx={{ mt: 1 }} style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1, lineHeight: "2", fontFamily:'OpenSansRegular' }}>
                                {name === 'Additional Location'?
                                    <div className='addon-content'>

                                    <div>
                                            {addlocPre[0]}
                                    </div>
                                        <div>
                                            {addlocPre[1]}
                                        </div>
                                </div>  
                            
                            :""}
                                {name === 'Additional User' ?
                                    <div className='addon-content'>

                                        <div>
                                            {addUserPre[0]}
                                        </div>
                                        <div>
                                            {addUserPre[1]}
                                        </div>
                                        <div>
                                            {addUserPre[2]}
                                        </div>
                                    </div>

                                    : ""}
                                {name === 'Additional Terminal' ?
                                    <div className='addon-content'>

                                        <div>
                                            {addTermPre[0]}
                                        </div>
                                        <div>
                                            {addTermPre[1]}
                                        </div>
                                        <div>
                                            {addTermPre[2]}
                                        </div>
                                    </div>

                                    : ""}

                                {name === 'Store Website, Monthly' ?
                                    <div className='addon-content'>

                                        <div>
                                            {addStoreWebsite[0]}
                                        </div>
                                        <div>
                                            {addStoreWebsite[1]}
                                        </div>

                                        <div>
                                            {addStoreWebsite[2]}
                                        </div>

                                        <div>
                                            {addStoreWebsite[3]}
                                        </div>

                                        <div>
                                            {addStoreWebsite[4]}
                                        </div>
                                        
                                    </div>

                                    : ""}
                            </MKTypography>

                        </MKBox>
                    </Grid>
                )}

                <Grid>
                    {modal && (
                        < AddOnModal planDetails={planDetails} setModal={setModal} />
                    )}
                </Grid>
                <Grid>
                    {locModal && (
                        < AddOnLocationModal planDetails={planDetails} setLocModal={setLocModal} />
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default AddonsList;