const footerData = {
    menu: [
        {
            title: "Company", data: [
                {
                    name: "About Us", href: "https://shelfperks.com/about"
                },
                {
                    name: "Become a Partner", href: "https://shelfperks.com/partners"
                },
                {
                    name: "Careers", href: "https://2497inc.com/careers"
                },
            ]
        },
        {
            title: "Explore", data: [
                {
                    name: "Types of Business", href: "https://shelfperks.com/business-types"
                },
                {
                    name: "Store Operating System", href: "https://shelfperks.com/store-operating-system"
                },
                {
                    name: "Enterprise Solutions", href: "https://shelfperks.com/enterprise"
                },
            ]
        },
        {
            title: "Resources", data: [
                {
                    name: "Blogs", href: "https://shelfperks.com/resources#Blogs"
                },
                {
                    name: "Guides", href: "https://shelfperks.com/resources#Blogs"
                },
                {
                    name: "Store Owner Hacks", href: "https://shelfperks.com/resources#storeownerhack"
                },
            ]
        },
        {
            title: "Assist", data: [
                {
                    name: "Get Help", href: "https://support.shelfperks.com/"
                },
                {
                    name: "Change Plan", href: "https://grow.shelfperks.com/#/authenticate"
                },
                {
                    name: "FAQs", href: "https://shelfperks.com/faq"
                },
            ]
        },
    ]
}


export default footerData;