import arrowRight from "../assets/images/search_arrow_new.svg";
import { withStyles } from "@material-ui/core/styles";
import TextField from '@mui/material/TextField';
import { useState } from "react";
import MKBox from "../components/MKBox";
import { useEffect } from "react";
import subscribeNewsLetter from "../providers/newsletter";
import { Grid } from "@mui/material";
import logo_pnumonic from "../assets/images/logo-pneumonic.svg";
import ClearIcon from '@mui/icons-material/Clear';

const ipify = require('ipify2');
const ipLocation = require('iplocation');


const MyTextField = withStyles(() => ({
    root: {
        "&.MuiInputLabel": {
            fontFamily: "OpenSansRegular"
        },
        "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
            color: "#F7931E",
            fontSize: "14px",
        },
        "& .MuiInput-root": {
            fontSize: "14px",
            fontFamily: "OpenSansMedium"
        },
        "& .MuiFormHelperText-root": {
            color: "#FF4040",
            fontFamily: "OpenSansMedium",
            fontSize: "11px",
        },
        "& .MuiFormLabel-root.Mui-error": {
            color: "#FF4040",
            fontSize: "12px",
            fontFamily: "OpenSansRegular",
        }
    }
}))(TextField)

export default function NewsLetter(setEmailError) {

    const [modal, setModal] = useState(false);
    const [navigator, setNavigator] = useState(typeof window !== "undefined" ? window.navigator.userAgent : "")
    const [ipv4v, setIpv4] = useState("")
    const [ipv6, setIpv6] = useState("")
    const [region, setRegion] = useState("")
    const acknowledgementImg = "https://asuygevbfkdyukgvsuledvf.shelfperks.com/spmktpr//assets/img/newsletter/acknowledgement.webp"
    useEffect(() => {
        setTimeout(async () => {
            ipify.ipv4().then(ipv4 => setIpv4(ipv4)).catch(err => console.log(err));
            ipify.ipv64().then(ipv64 => setIpv6(ipv64)).catch(err => console.log(err))
            if (ipv4v !== "") {
                const loc = await ipLocation(ipv4v);
                setRegion(loc["city"] + " " + loc["region"]["code"] + " " + loc["country"]["code"])
            }
        }, 2000)
    }, [])

    const [email, setEmail] = useState("");
    const [emailValidError, setEmailValidError] = useState(false);

    const toggleModal = () => {
        setModal(!modal);
    };

    if (modal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    const emailValidation = (email) => {
        const email_pattern = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,8})$/;
        if (!email_pattern.test(email)){
            setEmailValidError(true)
            setEmailError(true)
            return false;
        }else{
            setEmailValidError(false)
            setEmailError(false)
            return true;
        }
    }

    const handleSubmit = async () => {
        const validEmail = emailValidation(email);
        if (validEmail) {
            setEmailValidError(false);
            setEmailError(false)
            const formattedEmail = String(email).toLocaleLowerCase();
            const post_obj = {
                "email": formattedEmail,
                "ipv4_Address": ipv4v,
                "ipv6_Address": ipv6,
                "location": region,
                "userAgent": navigator,
            }

            const response = await subscribeNewsLetter(post_obj)
            if (response?.data?.status === 200){
                toggleModal()
                setEmail("")
            }
            
        } else {
            setEmailValidError(true)
            setEmailError(true)

        }
    }

   
    return (
        <>
        <MKBox sx={{fontSize: {xs:"24px", sm:"20px", md:"20px", lg:"32px", xl:"32px"}, fontWeight:{xs:"600", sm:"500"}}}>
            Keep up with us. Be the first to know.
            </MKBox>
            <MKBox>
            <MyTextField
                margin="normal"
                required
                fullWidth
                id="email"
                placeholder="Email address"
                label="Email address"
                name="email"
                value={email}
                    error={emailValidError}
                    helperText={emailValidError ? "Please enter a valid email address"
                    : ""}
                onChange={(e) => { setEmail(e.target.value) }}
                onBlur={(e) => emailValidation(e.target.value)}
                autoComplete="email"
                variant="standard"
                InputLabelProps={{
                    style: { fontFamily: "OpenSansRegular", fontSize: '14px' }
                }}
            />
            </MKBox>
            <MKBox style={{display:'flex', width:"100%", justifyContent:"end"}}>
                <span style={{ width: '24px', height: emailValidError?'16px':'20px', position: 'relative', left: '-16px', top: emailValidError ?'-68px' :"-46px" }}>
                    <button  style={{ background: 'none', outline: 'none', border: 'none', cursor: 'pointer' }}>
                        <img onClick={() => { handleSubmit()}} src={arrowRight} alt="right arrow" className="footer-right"></img>
                    </button>
                </span>
            </MKBox>
            <Grid>
                {modal && (
                    <>
                        <div className="modal">
                            <div className="overlay"><></></div>
                            <div className="modal-content">
                                <Grid className="" sx={{
                                    borderRadius:'8px',
                                    width: {xs:'100%', sm:'576px'},
                                    height:"563px",
                                    background: `url(${acknowledgementImg}) no-repeat`,
                                    backgroundSize: "cover",
                                }}>
                                    <div onClick={() => { toggleModal() }} style={{ position: "absolute", right: "20px", top: "20px", cursor: "pointer" }}>
                                        <ClearIcon sx={{ color: "#000000", height: "24px", width: "24px" }} />
                                    </div>

                                    <div className="thankYouBlock">

                                        {/* <Image loader={AssetsLoader} src={imageSrc} width={imageSizes.width} height={imageSizes.height} layout="intrinsic" /> */}
                                        <div className="contentBlock">

                                            <div className="thankYouText">
                                                Thank you for signing up!
                                            </div>
                                            <div className="thankYouContent">
                                                We’ll keep you informed on the latest updates and emerging trends to help grow your business.
                                            </div>
                                        </div>
                                        <img src={logo_pnumonic} width={24} height={24} alt="logo" />
                                    </div>
                                
                                </Grid>
                            </div>
                        </div>
                    </>
                )}
            </Grid>
        </>
    )
}