import * as React from 'react';
import { Grid, } from '@mui/material';
import Container from '@mui/material/Container';
import { Banner } from '../backgroundImage';
import { Detailsform } from '../form/detailsForm';
import { Verification } from '../form/verification';
import { Account } from '../form/account';
import { RegisterOne } from '../form/registerone';
import {MerchantUrl} from '../form/merchantUrl';
import {ChangePlan} from '../form/plan'
import ForgotPassword from "../form/forgotpassword";
import ForgotUsername from "../form/forgotUsername";
import ForgotMerchantID from "../form/forgotMerchantID";
import ForgotPasswordReset from '../form/forgotPasswordReset';
import { useState } from 'react'
import "../../App.css"
import logo from "../../assets/images/logo.svg";
import iPaddeviceBanner from "../../assets/images/iPaddeviceBanner.png";
import PremiumBanner from "../../assets/images/PremiumBanner.png";


import MKBox from '../../components/MKBox';
// import StickyFooter from "../../DefaultFooter/index";
import NewFooter from '../../footer/newFooter';
// import footerroutes from "../../footerroutes";
import {
    Routes,
    Route,
} from "react-router-dom";
import { Navigate } from "react-router-dom";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Check from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withStyles } from "@material-ui/core/styles";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';


const steps = ['Details', 'Verify', 'Activate'];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: 'white',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: 'white',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : 'white',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const MyStepper = withStyles(() => ({
    root: {
        "& .MuiStepLabel-label": {
            fontFamily: "OpenSansSemiBold",
            fontSize: "14px",
        },
        "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
            color: "#50B848"
        },
    }
}))(Stepper)

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#E6E6E6',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: "middle",
    ...(ownerState.active && {
        color: '#50B748',
    }),
    '& .QontoStepIcon-completedIcon': {
        height: '32px',
        width: '32px',
        zIndex: 1,
        backgroundColor: '#DEFEDC',
        borderRadius: '16px',
        verticalAlign: "middle",
    },
    '& .QontoStepIcon-circle': {
        width: 32,
        height: 32,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
        verticalAlign: "middle",
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;


    const icons = {
        1: '1',
        2: '2',
        3: '3',
    };

    const countColor = active ? "#FFFFFF" : "#5B5B5B"

    const stepCountobj = {
        color: countColor,
        height: "100%",
        textAlign: "center",
        verticalAlign: "middle",
        lineHeight: "32px",
        fontSize: "14px",
        fontFamily: "OpenSansRegular",
    }
    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <div className="QontoStepIcon-completedIcon">
                    <Check
                        sx={{
                            color: '#50B748',
                            // fontSize: '10px',
                            marginLeft: '7px',
                            marginTop: '7px',
                            fontSize: "18px !important",
                        }}
                    />
                </div>
            ) : (
                <div className="QontoStepIcon-circle">
                    <div style={stepCountobj}>{icons[String(props.icon)]}</div>
                </div>
            )
            }
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    icon: PropTypes.node,
};

export function HorizontalStepper({ stepperCount }) {
    const [activeStep, setActiveStep] = React.useState(0);
    if (stepperCount !== activeStep) {
        setActiveStep(stepperCount);
    }

    return (

        <MKBox sx={{
            opacity: 1,
            justifyContent: "center",
        }}>
            <MyStepper activeStep={activeStep} className="stepper-width"
                sx={{
                    height: "auto",
                    width: {
                        xs: "100%",
                        sm: "425px",
                        md: "415px",
                        lg: "400px"
                    },
                    mt: "20px"
                }}
                connector={<QontoConnector />}
            >
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps} >
                            <StepLabel StepIconComponent={QontoStepIcon}
                                style={{
                                    fontFamily: "OpenSansRegular",
                                }}{...labelProps}
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </MyStepper>
        </MKBox>
    )
}


export function BasicLayout() {
    const [inviteCodeValidated, setInviteCodeValidated] = useState(false);
    const [stepperCount, setStepperCount] = useState(-1);

    return (
        <>
            <MKBox mb='18px' style={{ minHeight: "calc(100vh - 200px)" }}>
                <Container style={{ overflowX: "hidden" }} p={0}>
                    <Grid container m={0} >
                        <Grid item xs={0} xl={6} lg={6} md={6}>
                            <Banner />
                        </Grid>
                        <Grid item xs={12} xl={6} lg={6} md={6} p={1}>
                            <Grid container justifyContent='center'>
                                {stepperCount !== -1 ?
                                <>
                                    <Grid item className='logo-container originalLogo' xs={0} lg={4} md={4.5} sm={0} xl={4} >
                                        <div className='logo'>
                                            <img src={logo} width="203px" height="36px" alt="LOGO"></img>
                                        </div>
                                    </Grid>
                                        <Grid item className='logo-container premiumLogo' sx={{justifyContent:'center'}} xs={0} lg={4} md={4.5} sm={0} xl={4} >
                                        <div className='bannerLogo'>
                                                <img src={iPaddeviceBanner} width="343px" height="140px" alt="IPADBANNER"></img>
                                                <img src={PremiumBanner} width="343px" height="110px" alt="PREMIUM"></img>       
                                        </div>
                                    </Grid>
                                    </>
                                    : ""}
                                {stepperCount !== -1 ?
                                    <Grid item className='stepper-container' xs={12} lg={4} md={4.5} sm={7} xl={4} sx={{ mt: { lg: 9, md: 9 } }}>
                                        <HorizontalStepper stepperCount={stepperCount} className='stepper' />
                                    </Grid>
                                    : ""}
                                <Grid item
                                    xs={12}
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    m={1}
                                    justifyContent='center'
                                    mt={0}
                                // height="375px"
                                >
                                    <Routes>
                                        <Route path="/changeplan" element={<ChangePlan setStepperCount={setStepperCount} />} />

                                        <Route exact path="/authenticate" element={<MerchantUrl setStepperCount={setStepperCount} />} />
                                        <Route exact path="/register" element={<Detailsform setStepperCount={setStepperCount} />} />
                                        <Route exact path="/register/complete" element={<Verification setStepperCount={setStepperCount} setInviteCodeValidated={setInviteCodeValidated} />} >
                                            <Route path=":verifycode" element={<Verification setStepperCount={setStepperCount} setInviteCodeValidated={setInviteCodeValidated} />} />
                                        </Route>
                                        <Route exact path="/register/account" element={<Account setStepperCount={setStepperCount} inviteCodeValidated={inviteCodeValidated} />} >
                                            <Route path=":verifycode" element={<Verification setStepperCount={setStepperCount} />} />
                                        </Route>

                                        <Route exact path="/registerone" element={<RegisterOne setStepperCount={setStepperCount} />} />
                                        <Route exact path="/" element={<Navigate replace to="/register" setStepperCount={setStepperCount} />} />
                                        <Route path="*" element={<Navigate replace to="/register" setStepperCount={setStepperCount} />} />
                                        <Route path="/forgot/password" exact element={<ForgotPassword setStepperCount={setStepperCount} />} />
                                        <Route path="/forgot/username" exact element={<ForgotUsername setStepperCount={setStepperCount} />} />
                                        <Route path="/forgot/merchantid" exact element={<ForgotMerchantID setStepperCount={setStepperCount} />} />
                                        <Route path="/forgot/restpass" exact element={<ForgotPasswordReset setStepperCount={setStepperCount} />} >
                                            <Route path=":tokenref" element={<ForgotPasswordReset setStepperCount={setStepperCount} />} />
                                        </Route>
                                    </Routes>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            {/* <Container maxWidth="lg" mt={6} style={{ padding: 0, }}>
                <StickyFooter content={footerroutes} />
            </Container> */}
            <MKBox pt={0.7} mt={0} className="footerXL" mb={0} sx={{
                backgroundColor: "#F0EEEE"

                }} >
                <Container maxWidth="lg">
                    <NewFooter />
                    {/* <StickyFooter   content={footerroutes} /> */}
                </Container>
                {/* <MKBox className="navybg" sx={{ backgroundColor: "#2E4455", }} /> */}
            </MKBox>
        </>
    )

}