import Grid from "@mui/material/Grid";
import MKTypography from "../components/MKTypography";
import MKBox from "../components/MKBox";
import Insta from "../assets/images/footer/instaSvg.svg";
import fb from "../assets/images/footer/fbSvg.svg"
import tick from "../assets/images/footer/tiktokSvg.svg"
import tweet from "../assets/images/footer/twitterSvg.svg"
import ytube from "../assets/images/footer/youtubeSvg.svg"
import linkedin from "../assets/images/footer/linkedinSvg.svg"
import threads from "../assets/images/footer/threadsSvg.svg"

const SocialIcons = () => {
    return (
        <Grid container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        // style={{fontFamily: "Arial" }}
        >
            <Grid item xs={12} container style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }} mb="0px" >
                <div>
                    <a target="_blank" rel="noreferrer" href='https://www.instagram.com/shelfperks/'>
                        <MKTypography display="inline-block" color="white">
                            <img src={Insta} className="social-icons" height={24} width={24} alt="instagram" />
                        </MKTypography >
                    </a>
                </div>
                <div>

                    <a target="_blank" rel="noreferrer" href='https://twitter.com/shelfperks'>
                        <MKTypography display="inline-block" color="white">
                            <img src={tweet} className="social-icons" height={24} width={24} alt="tween" />
                        </MKTypography >
                    </a>
                </div>
                <div>
                    <a target="_blank" rel="noreferrer" href='https://www.facebook.com/ShelfPerks'>
                        <MKTypography display="inline-block" color="white">
                            <img src={fb} className="social-icons" height={24} width={24} alt="facebook" />
                        </MKTypography >
                    </a>

                </div>
                <div>
                    <a target="_blank" rel="noreferrer" href='https://www.tiktok.com/@shelfperks'>
                        <MKTypography display="inline-block" color="white">
                            <img src={tick} className="social-icons" height={24} width={24} alt="tictok" />
                        </MKTypography >
                    </a>
                </div>
                <div>
                    <a target="_blank" rel="noreferrer" href='https://www.youtube.com/channel/UCbcwcwtDvQd87PciEEql5PQ'>
                        <MKTypography display="inline-block" color="white">
                            <img src={ytube} className="social-icons" height={24} width={24} alt="youtube" />
                        </MKTypography >
                    </a>
                </div>

                <div>

                    <a target="_blank" rel="noreferrer" href='https://www.threads.net/@shelfperks/'>
                        <MKTypography display="inline-block" color="white">
                            <img src={threads} className="social-icons" height={24} width={24} alt="threads" />
                        </MKTypography >
                    </a>
                </div>
                <div>

                    <a target="_blank" rel="noreferrer" href='https://www.linkedin.com/company/shelfperks/'>
                        <MKTypography display="inline-block" color="white">
                            <img src={linkedin} className="social-icons" height={24} width={24} alt="linkedin" />
                        </MKTypography >
                    </a>
                </div>

                


            </Grid>
        </Grid>
    )
}

export default SocialIcons;